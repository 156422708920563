import { ref } from "vue";

export const notificationCount = ref(0);

export const countToRef = ref(null);

export const changeCount = (count) => {
  notificationCount.value = count;
  countToRef.value?.count(notificationCount.value);
};
