import { computed, ref } from "vue";
import { API } from "@/api";
import { notificationCount } from "@/modules/log/helpers/notification";
import router from "@/router";
import { defineStore } from "pinia";

export const useAuth = defineStore("auth", () => {
  const userData = ref({});
  const isAuth = computed(() => !!userData.value?.id);
  const login = (DTO) => {
    return API.post("/auth/login", DTO).then((res) => {
      notificationCount.value = res.data.unread_action_logs_count;
      const resData = res.data.data;
      const { access_token, refresh_token, user } = resData;
      initToken(access_token, refresh_token, user);
      return true;
    });
  };

  const refreshToken = () => {
    const refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
    const params = new Object({ headers: { Authorization: `Bearer ${refresh_token}` } });
    return API.post("/auth/refresh", {}, params).then((res) => {
      const resData = res.data.data;
      const { access_token, refresh_token, user } = resData;
      initToken(access_token, refresh_token, user);
      return access_token;
    });
  };
  const getMe = () => {
    return API.get("/users/me").then((res) => {
      notificationCount.value = res.data.unread_action_logs_count;
      userData.value = res.data.data;
      localStorage.setItem("user", JSON.stringify(userData.value));
    });
  };
  const logout = () => {
    return API.post("/auth/logout").then(() => {
      DestructToken();
    });
  };
  const initToken = (access_token, refresh_token, user) => {
    localStorage.setItem("access_token", JSON.stringify(access_token));
    localStorage.setItem("refresh_token", JSON.stringify(refresh_token));
    localStorage.setItem("user", JSON.stringify(user));
    userData.value = user;
    API.defaults.headers.common["Authorization"] = "Bearer " + access_token;
  };
  const DestructToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    API.defaults.headers.common["Authorization"] = null;
    userData.value = {};
    router.push({ name: "login" });
  };
  const loadApp = () => {
    if (localStorage.getItem("access_token")) {
      const access_token = JSON.parse(localStorage.getItem("access_token"));
      userData.value = JSON.parse(localStorage.getItem("user"));
      API.defaults.headers.common["Authorization"] = "Bearer " + access_token;
    }
  };
  const frontendComponentsRules = (location, key) => {
    const components = userData.value?.frontend_components?.[location];
    return components && components.includes(key);
  };

  return {
    userData,
    isAuth,
    login,
    refreshToken,
    logout,
    DestructToken,
    loadApp,
    getMe,
    frontendComponentsRules,
  };
});
